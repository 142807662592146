import { LeftOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Typography } from 'antd';
import React, { PropsWithChildren, ReactNode } from 'react';
import { To, useNavigate } from 'react-router-dom';
import styles from './pageItems.module.scss';

interface BackLink {
  text: string;
  to: To;
}
interface PageContentProps extends PropsWithChildren {
  title?: ReactNode;
  extra?: ReactNode[];
  backLink?: BackLink;
}

export const PageContent: React.FunctionComponent<PageContentProps> = ({ title, extra, children, backLink }) => {
  const navigate = useNavigate();

  const goBack = () => navigate(backLink?.to || '/');
  return (
    <div className={styles.page}>
      {backLink && (
        <Button type="link" icon={<LeftOutlined />} onClick={goBack} className={styles.back}>
          {backLink.text}
        </Button>
      )}
      <Card className={styles.content}>
        <Row gutter={16}>
          <Col flex={1}>
            <Typography.Title level={2} color="primary">
              {title}
            </Typography.Title>
          </Col>
          {extra && extra.map((x, i) => <Col key={i}>{x}</Col>)}
        </Row>

        {children}
      </Card>
    </div>
  );
};
