import { TenantContext } from '@/lib/context';
import { useFinancialYearContext } from '@/lib/context/financialYearContext';
import { RelationContext } from '@/lib/context/relationContext';
import { InteractionStatus } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { Layout, Skeleton } from 'antd';
import { Suspense, useContext } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MainHeader } from '../lib/components/core/layout/mainHeader';
import { SideNav } from '../lib/components/core/layout/sideNav';
import styles from './styles.module.scss';

export const BaseLayout = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { instance, inProgress } = useMsal();
  const account = useAccount();

  const { relation, setRelation } = useContext(RelationContext);
  const { tenant, setTenant } = useContext(TenantContext);
  const { financialYear } = useFinancialYearContext();

  const signOut = () => {
    instance.logoutRedirect({
      account: account,
    });
  };

  return (
    <Skeleton loading={inProgress === InteractionStatus.HandleRedirect}>
      <Layout className={styles.main}>
        <MainHeader
          user={account?.username}
          relation={relation}
          tenant={tenant}
          onSwitchRelation={() => setRelation(undefined)}
          onSignOut={signOut}
          onSwitchTenant={() => {
            setRelation(undefined);
            setTenant(undefined);
          }}
          onServiceSelect={() => navigate('/service')}
        />

        <Suspense fallback={<Skeleton active loading />}>
          <SideNav selectedKey={location.pathname} onSelect={navigate} relation={relation} financialYear={financialYear} />
          <div className={styles.content}>
            <Outlet />
          </div>
        </Suspense>
      </Layout>
    </Skeleton>
  );
};
