import { VeebalansBeknoptItem } from '@/lib/types/reports';
import { BaseTableComponentProps } from '@/lib/types/table';
import { ColumnGroupType } from 'antd/es/table';
import i18next from 'i18next';
import React from 'react';
import { ExtendableColumnType, ExtendedTable } from '../core';

interface VeebalansBeknoptTableProps extends Omit<BaseTableComponentProps<VeebalansBeknoptItem>, 'pagination'> {}

const t = i18next.getFixedT(null, 'reports', 'fields.veebalans');

const createColumn = (column: string, groupName?: string, props?: ExtendableColumnType<VeebalansBeknoptItem>): ExtendableColumnType<VeebalansBeknoptItem> => {
  return {
    key: column,
    dataIndex: column,
    title: t(column, column),
    groupKey: groupName ? t(groupName, groupName) : undefined,
    render: (value: number) => value.toLocaleString('nl-NL'),
    summary: true,
    ...props,
  };
};

export const VeebalansBeknoptTable: React.FunctionComponent<VeebalansBeknoptTableProps> = ({ items, isLoading, onTableChange }) => {
  const columns: (ExtendableColumnType<VeebalansBeknoptItem> | ColumnGroupType<VeebalansBeknoptItem>)[] = [
    createColumn('dierCategorieOmschrijving', undefined, { fixed: 'left', summary: false, render: undefined }),
    createColumn('dierCategorieGeslacht', undefined, { summary: false }),
    createColumn('beginbalansAantal', 'beginBalans'),
    createColumn('beginbalansWaarde', 'beginBalans'),
    createColumn('geboorteAantal', 'geboorte'),
    createColumn('aankoopAantal', 'aankoop'),
    createColumn('aankoopWaarde', 'aankoop'),
    createColumn('categorieBijAantal', 'categorieBij'),
    createColumn('categorieAfAantal', 'categorieAf'),
    createColumn('sterfteAantal', 'sterfte'),
    createColumn('verkoopAantal', 'verkoop'),
    createColumn('verkoopWaarde', 'verkoop'),
    createColumn('eindBalansAantal', 'eindBalans'),
    createColumn('eindBalansWaarde', 'eindBalans'),
  ];

  return (
    <ExtendedTable
      bordered
      exportable={{ enabled: false }}
      loading={isLoading}
      rowKey={x => `${x.dierCategorieOmschrijving}-${x.dierCategorieGeslacht}`}
      dataSource={items}
      columns={columns}
      pagination={false}
      onChange={onTableChange}
      summary={{
        enabled: true,
        cellRender: v => v?.toFixed(2),
      }}
    />
  );
};
