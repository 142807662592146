export const createFilterParams = (filter?: { [key: string]: unknown }): URLSearchParams | undefined => {
  if (!filter) {
    return;
  }

  const valueIsKeyValue = (value: unknown): boolean => {
    return !!value && typeof value === 'object' && 'value' in value && 'key' in value;
  };

  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(filter)) {
    if (typeof value === 'undefined') {
      continue;
    }

    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        const item = value[i];
        const itemKey = `${key}[${i}]`;

        if (valueIsKeyValue(item)) {
          params.append(`${itemKey}.key`, item.key);
          params.append(`${itemKey}.value`, item.value);
        } else {
          if (typeof item === 'object') {
            const flattened = flattenObject(item);
            for (const [subkey, subValue] of Object.entries(flattened)) {
              if (typeof subValue !== 'undefined') params.append(`${itemKey}.${subkey}`, subValue as string); // object is flattened, so no sub object should be possible at this point
            }
            continue;
          } else {
            params.append(`${itemKey}`, item);
          }
        }
      }
      continue;
    }

    if (value instanceof Date) {
      params.append(key, value.toISOString());
      continue;
    }

    if (typeof value == 'object') {
      const flattened = flattenObject(value as object);
      for (const [subkey, value] of Object.entries(flattened)) {
        if (typeof value !== 'undefined') params.append(`${key}.${subkey}`, value as string); // object is flattened, so no sub object should be possible at this point
      }
      continue;
    }

    params.append(key, value as string);
  }

  return params;
};

function flattenObject(obj: object, parent: string | null = null, res = {}) {
  for (const [key, value] of Object.entries(obj)) {
    const propName = parent ? parent + '.' + key : key;
    if (typeof value == 'object') {
      flattenObject(value, propName, res);
    } else {
      res[propName] = value;
    }
  }
  return res;
}
