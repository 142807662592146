import Logo from '@/assets/logo.png';
import { FeatureFlagContext } from '@/lib/context';
import { FeatureFlags, Relation, Tenant } from '@/lib/types';
import { FinancialYear } from '@/lib/types/financialyear';
import { dateTimeFormatter } from '@/lib/utilities/formatters';
import { CalendarOutlined, FileTextOutlined, LockOutlined, ReadOutlined, TeamOutlined } from '@ant-design/icons';
import { Button, Grid, Layout, Menu, MenuProps } from 'antd';
import i18next from 'i18next';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './core.components.module.scss';

type MenuItem = Required<MenuProps>['items'][number];
const { Sider } = Layout;

interface SiderProps {
  selectedKey: string;
  onSelect: (key: string) => void;
  relation?: Relation;
  tenant?: Tenant;
  financialYear?: FinancialYear;
}

const createMenuItem = (label: string, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: string): MenuItem =>
  ({
    label,
    key,
    icon,
    children,
    type,
  }) as MenuItem;

const createMenu = (flags: FeatureFlags, relationSelected) => {
  const t = i18next.getFixedT(null, 'core');
  const items: MenuProps['items'] = [];

  if (relationSelected) {
    // Add all the relations context item
    items.push(
      createMenuItem(t('menu.relation.section'), '/relation', <TeamOutlined />, [
        createMenuItem(t('menu.relation.details'), '/relation/details'),
        createMenuItem(t('menu.relation.ubns'), '/relation/ubns'),
        createMenuItem(t('menu.relation.financialYears'), '/relation/financial-years'),
      ])
    );
    const animalAdministrationItems: MenuProps['items'] = [];

    // Add all animal items
    if (flags['show-animals-overview']) {
      animalAdministrationItems.push(createMenuItem(t('menu.animals.search'), '/animals'));
    }
    if (flags['show-mutations-overview']) {
      animalAdministrationItems.push(createMenuItem(t('menu.animals.mutations'), '/animals/mutations'));
    }
    if (flags['show-start-balance-overview']) {
      animalAdministrationItems.push(createMenuItem(t('menu.animals.startBalance'), '/animals/startbalance'));
    }
    if (flags['show-import-overview']) {
      animalAdministrationItems.push(createMenuItem(t('menu.animals.import'), '/animals/import'));
    }
    if (animalAdministrationItems.length) {
      items.push(createMenuItem(t('menu.animals.section'), '/animals#', <ReadOutlined />, animalAdministrationItems));
    }

    // Add all report items
    const reportItems: MenuProps['items'] = [];
    if (flags['show-reports-veebalans']) {
      reportItems.push(createMenuItem(t('menu.reports.veebalans'), '/reports/veebalans'));
      reportItems.push(createMenuItem(t('menu.reports.veebalansBeknopt'), '/reports/veebalans-beknopt'));
      reportItems.push(createMenuItem(t('menu.reports.omzetAanwas'), '/reports/omzet-aanwas'));
    }
    if (reportItems.length) {
      items.push(createMenuItem(t('menu.reports.section'), '/reports', <FileTextOutlined />, reportItems));
    }
  }

  return items;
};

export const SideNav: React.FunctionComponent<SiderProps> = ({ selectedKey, relation, financialYear, onSelect }) => {
  const { t } = useTranslation('core');
  const flags = useContext(FeatureFlagContext);

  const screens = Grid.useBreakpoint();

  const items: MenuProps['items'] = createMenu(flags, !!relation);

  return (
    <Sider className={styles.sideNav} collapsed={!screens.lg} width={250}>
      <div className={styles.sideNav__logo}>
        <div className={styles.sideNav__logo__img}>
          <img src={Logo}></img>
        </div>
        {screens.md ? <h1 className={styles.sideNav__logo__title}>{t('title')}</h1> : null}
      </div>
      <Menu className={styles.sideNav__menu} selectedKeys={[selectedKey]} onSelect={e => onSelect(e.key)} mode="inline" items={items} />
      {relation && (
        <div style={{ marginTop: 'auto' }}>
          <Button icon={financialYear?.closed ? <LockOutlined /> : null} type="primary" block onClick={() => onSelect('/animals/financial-year-selection')}>
            {screens.lg ? (
              financialYear ? (
                t('menu.extra.financialYear.selected', {
                  financialYear: `${dateTimeFormatter.toDateOnly(financialYear.startDate)} - ${dateTimeFormatter.toDateOnly(financialYear.endDate)}`,
                })
              ) : (
                t('menu.extra.financialYear.noSelected')
              )
            ) : (
              <CalendarOutlined />
            )}
          </Button>
        </div>
      )}
    </Sider>
  );
};
