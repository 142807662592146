import { Result, Skeleton } from 'antd';
import { FunctionComponent, PropsWithChildren, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFeatureFlags } from '../queries/featureFlags';
import { FeatureFlags } from '../types/featureFlags';

const initialData: FeatureFlags = {
  'allow-service-tenant-selection': false,
  'allow-service-tools': false,
  'show-create-relation': false,
  'show-animals-overview': false,
  'show-update-relation': false,
  'show-ownership-functionality': false,
  'show-add-relation-location': false,
  'show-add-rearing-location': false,
  'show-import-overview': false,
  'show-request-mandate': false,
  'show-start-balance-batch': false,
  'show-start-balance-overview': false,
  'show-add-lifenumbers': false,
  'show-add-ownership': false,
  'show-reports-kengetallen': false,
  'show-reports-veebalans': false,
  'show-reports-veebalans-beknopt': false,
  'show-mutations-overview': false,
};

export const FeatureFlagContext = createContext<FeatureFlags>(initialData);

export const FeatureFlagProvider: FunctionComponent<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation('core');

  const { data, isLoading, error } = useGetFeatureFlags();

  return (
    <FeatureFlagContext.Provider value={data || initialData}>
      {error ? (
        <Result status="error" title={t('loadingErrors.featureFlags.title')} subTitle={t('loadingErrors.featureFlags.subTitle')} />
      ) : (
        <Skeleton loading={isLoading}>{children}</Skeleton>
      )}
    </FeatureFlagContext.Provider>
  );
};
