import { OmzetAanwasItem } from '@/lib/types/reports';
import { BaseTableComponentProps } from '@/lib/types/table';
import { ColumnGroupType } from 'antd/es/table';
import i18next from 'i18next';
import React from 'react';
import { ExtendableColumnType, ExtendedTable } from '../core';

interface OmzetAanwasTableProps extends Omit<BaseTableComponentProps<OmzetAanwasItem>, 'pagination'> {}

const t = i18next.getFixedT(null, 'reports', 'fields.veebalans');

const createColumn = (column: string, groupName?: string, props?: ExtendableColumnType<OmzetAanwasItem>): ExtendableColumnType<OmzetAanwasItem> => {
  return {
    key: column,
    dataIndex: column,
    title: t(column, column),
    groupKey: groupName ? t(groupName, groupName) : undefined,
    render: (value: number) => value?.toLocaleString('nl-NL'),
    summary: true,
    ...props,
  };
};

export const OmzetAanwasTable: React.FunctionComponent<OmzetAanwasTableProps> = ({ items, isLoading, onTableChange }) => {
  const columns: (ExtendableColumnType<OmzetAanwasItem> | ColumnGroupType<OmzetAanwasItem>)[] = [
    createColumn('levensNummer', undefined, { fixed: 'left', summary: false, render: undefined }),
    createColumn('beginBalansDierCategorie', 'beginBalans', { summary: false }),
    createColumn('beginbalansWaarde', 'beginBalans'),
    createColumn('beginbalansCorrectie', 'beginBalans'),
    createColumn('beginbalansHerwaardering', 'beginBalans'),
    createColumn('aankoopWaarde', 'aankoop'),
    createColumn('verkoopWaarde', 'verkoop'),
    createColumn('aanwasWaarde', 'aanwas'),
    createColumn('verliesWinstWaarde'),
    createColumn('eindBalansDierCategorie', 'eindBalans', { summary: false }),
    createColumn('eindBalansWaarde', 'eindBalans'),
  ];

  return (
    <ExtendedTable
      bordered
      exportable={{ enabled: false }}
      loading={isLoading}
      rowKey={x => `${x.levensNummer}-${x.dierCategorieOmschrijving}-${x.dierCategorieGeslacht}`}
      dataSource={items}
      columns={columns}
      pagination={false}
      onChange={onTableChange}
    />
  );
};
